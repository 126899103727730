import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TextImageSlider({images, text}) {

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <i
      className={`arrow arrow-right`}
      onClick={onClick}
    >
      &rarr;
    </i>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <i
      className={`arrow arrow-left`}
      onClick={onClick}
    >
      &larr;
    </i>
  );
}

  return (
    <section className="image-slider-text-block">
      <div className="text">{parse(text)}</div>
      
      <Slider className="image-slider slick" {...settings}>
        {
          images.map(image => <GatsbyImage image={image.image.localFile.childImageSharp.gatsbyImageData} alt={image.image.altText} />)
        }
      </Slider>        
    </section>
  )
}
