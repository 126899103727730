import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as basicLightbox from 'basiclightbox'

export default function Gallery({
  gallery,
  heading
}) {

  const createLightbox = (image) => {
    console.log(image)
    basicLightbox.create(`
		<img src="${image}"  width="1400" height="900"  />
	`).show()
  }

  return (
    <>
      <section className="gallery">    
        <h2>{heading}</h2>  
        <ul className="gallery-images">
          {
            gallery.map(image => {
              return (
                <li className="gallery-image">
                    {
                      image.imageLarge !== null || image.imageLarge !== undefined ? 
                        <div onClick={() => createLightbox(image?.image?.localFile?.childImageSharp?.original.src)}>
                          <GatsbyImage image={image?.image?.localFile?.childImageSharp?.gatsbyImageData} />
                        </div>
                      : <GatsbyImage image={image.image.localFile.childImageSharp.gatsbyImageData} />
                    }
                </li>
              )
            })
          }
        </ul>
      </section>
    </>
  )
}
