import React from 'react'

export default function Video({heading, video}) {
  return (
    <section className="video-block">
      <h2>{heading}</h2>
      <div className="videoWrapper">
        <iframe width="560" height="315" src={`https://www.youtube.com/embed/${video}`} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
    </section>
  )
}
