import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function HeroImage({image}) {
  return (
    <section className="hero-image">
      <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData} />
    </section>
  )
}
