import React from 'react'
import parse from 'html-react-parser'

export default function Text({text}) {
  return (
    <section className="full-width-text"> 
      <div className="text">{parse(text)}</div> 
    </section>  
  )
}
