import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

export default function TextImage({image, text}) {
  return (
    <section className="image-text-block">
      <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.altText} />
      <div className="text">{parse(text)}</div>
    </section>
  )
}
