import React from "react";
import { graphql } from "gatsby";
import Button from "../components/flexibleContent/Button";
import FourImages from "../components/flexibleContent/FourImages";
import Gallery from "../components/flexibleContent/Gallery";
import HeroImage from "../components/flexibleContent/HeroImage";
import Text from "../components/flexibleContent/Text";
import TextImage from "../components/flexibleContent/TextImage";
import TextImageSlider from "../components/flexibleContent/TextImageSlider";
import Video from "../components/flexibleContent/Video";
import Seo from "../components/global/Seo";

export default function FlexiblePages({ data }) {
  const page = data.wpPage;
  const flexContent = data.wpPage.addYourOwnContentBlocks.pickAContentBlock;
  return (
    <div className="page-template-flexible-content">
      <Seo title={page.title} description="With origins dating from 1874, the distinguished Manchester Tennis &amp; Racquet Club (MTRC) has been situated on Blackfriars Road in Salford since 1880. This truly unique members' club is one of Manchester's greatest hidden treasures." />
      {flexContent.map((flex, index) => {
        if (flex.fieldGroupName.includes("ImageTextBlock")) {
          return <TextImage key={`flexItem_${index}`} image={flex.image} text={flex.text} />;
        }
        if (flex.fieldGroupName.includes("TextBlockImageSlider")) {
          return <TextImageSlider key={`flexItem_${index}`} images={flex.imageSlider} text={flex.text} />;
        }
        if (flex.fieldGroupName.includes("Video")) {
          return <Video key={`flexItem_${index}`} video={flex.videoEmbedCode} heading={flex.heading} />;
        }
        if (flex.fieldGroupName.includes("Gallery")) {
          return <Gallery key={`flexItem_${index}`} gallery={flex.gallery} heading={flex.heading} />;
        }
        if (flex.fieldGroupName.includes("HeroImage")) {
          return <HeroImage key={`flexItem_${index}`} image={flex.image} />;
        }
        if (flex.fieldGroupName.includes("FullWidthText")) {
          return <Text key={`flexItem_${index}`} text={flex.text} />;
        }
      })}
    </div>
  );
}

export const query = graphql`
  query FlexiblePageQuery($id: String) {
    wpPage(id: { eq: $id }) {
      title
      addYourOwnContentBlocks {
        pickAContentBlock {
          ... on WpPage_Addyourowncontentblocks_PickAContentBlock_HeroImage {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Addyourowncontentblocks_PickAContentBlock_FullWidthText {
            text
            fieldGroupName
          }
          ... on WpPage_Addyourowncontentblocks_PickAContentBlock_Gallery {
            fieldGroupName
            heading
            gallery {
              caption
              image {
                altText
                publicUrl
                localFile {
                  absolutePath
                  childImageSharp {
                    gatsbyImageData
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Addyourowncontentblocks_PickAContentBlock_ImageTextBlock {
            fieldGroupName
            text
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Addyourowncontentblocks_PickAContentBlock_TextBlockImageSlider {
            fieldGroupName
            text
            imageSlider {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Addyourowncontentblocks_PickAContentBlock_Video {
            fieldGroupName
            heading
            videoEmbedCode
          }
          ... on WpPage_Addyourowncontentblocks_PickAContentBlock_4Images {
            fieldGroupName
            heading
            images {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Addyourowncontentblocks_PickAContentBlock_Button {
            buttonText
            fieldGroupName
          }
        }
      }
    }
  }
`;
